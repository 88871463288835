<template>
  <div>
    <eden-page-stats
      class="mt-0"
      :title="'Overview'"
      :loading="loading"
      :stats="stats"
      :show-periods="false"
    >
      <template slot="actions">
        <eden-periods
          class="mr-10"
          :loading="loading"
          :default-period="'today'"
          :show-custom-period="true"
          :custom-title="'Orders for'"
          @set-period="getProvisionedOrders"
        />
        <eden-service-partners
          :allow-all="true"
          :service-partner-name.sync="servicePartnerName"
          :service-partner-id.sync="servicePartnerId"
          :service="'beauty'"
          :loading="loading"
        />
      </template>
    </eden-page-stats>
    <provisioned-orders-view
      :loading="loading"
      :service="'beauty'"
      :period="period"
      :orders="setProvisionedOrders"
      :orders-time="ordersTime"
      @confirmed="getProvisionedOrders({ period })"
    />
  </div>
</template>

<script>
import ProvisionedOrdersView from "@/components/ServiceOperations/ProvisionedOrders/ProvisionedOrdersView";

import { parseProvisionedOrders } from "../provisioned-orders.config";
import * as actions from "@/store/action-types";

export default {
  name: "ProvisionedOrdersBeauty",
  components: {
    ProvisionedOrdersView,
  },
  data() {
    return {
      loading: false,
      period: "today",
      periods: [
        "lastweek",
        "yesterday",
        "today",
        "tomorrow",
        "nexttwodays",
        "thisweek",
        "nextweek",
      ],
      custom: {
        from: null,
        to: null,
      },
      servicePartnerName: "all",
      servicePartnerId: "all",
      provisionedOrders: {
        all: {},
      },
      ordersTime: "",
    };
  },
  computed: {
    setProvisionedOrders() {
      return this.provisionedOrders[this.servicePartnerName].orders;
    },
    stats() {
      const { customers, orders, confirmed, edited, incomplete, types } =
        this.provisionedOrders[this.servicePartnerName];

      let stats = [
        {
          label: "Total customers",
          figure: (customers && customers.length) || "-",
        },
        {
          label: "Total orders",
          figure: (orders && orders.length) || "-",
        },
        {
          label: "Orders confirmed",
          figure: orders && orders.length ? confirmed : "-",
        },
        {
          label: "Orders edited",
          figure: orders && orders.length ? edited : "-",
        },
        {
          label: "Orders  incomplete",
          figure: orders && orders.length ? incomplete : "-",
        },
      ];

      let beautyTypes = types && Object.keys(types);
      if (beautyTypes && beautyTypes.length) {
        beautyTypes.forEach((type) => {
          stats.push({
            label: `${this.formatText(type)} beauty orders`,
            figure: types[type].quantity,
          });
        });
      }

      return stats;
    },
  },
  created() {
    this.getProvisionedOrders({
      period: this.period,
      from: this.custom.from,
      to: this.custom.to,
    });
  },

  methods: {
    getProvisionedOrders({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period: this.period,
        from,
        to,
      });
      this.$store
        .dispatch(actions.GET_PROVISIONED_ORDERS, {
          service: "beauty",
          start_date,
          end_date,
          from,
          to,
        })
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.period = period;
            this.provisionedOrders = parseProvisionedOrders(
              data.data,
              "beauty",
            );
            this.ordersTime = new Date();
            this.loading = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
  },
};
</script>
